import React from "react"

const Copyright = () => {
    return (
        <div className="copyright py-4 text-center text-white">
          <div className="container">
            <small>Copyright &copy; Kshitij Portfolio 2023</small>
          </div>
        </div>
    )
}

export default Copyright;